import React from "react";
import { Img } from "@raketa-cms/raketa-cms";

const SimilarProjects = ({ projects }) => (
  <div className="last-slide-inner">
    <div className="suggested-prjects-wrapper">
      <h1 className="last-slide-title">Similar Projects</h1>
      <div className="suggested-projects">
        {projects.map((project) => (
          <a href={project.url} className="project" key={project.id}>
            <Img src={project.image} variant="thumb" />

            <div className="overlay-content">
              <h2 className="title">{project.name}</h2>
            </div>
          </a>
        ))}
      </div>

      <div className="btn-wrapper">
        <a href="/contact" className="btn-contact">
          Contact us
        </a>
      </div>
    </div>
  </div>
);

export default SimilarProjects;
