import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';
import MosaicItem from './MosaicItem';

const BigImageLeftLayout = ({ image_1, image_2, link_1, link_2, title_1, title_2, description_1, description_2 }) => (
  <div className="grid">
    <div className="eight">
      <MosaicItem variant="square" image={image_1} link={link_1} title={title_1} description={description_1} />
    </div>
    <div className="third">
      <MosaicItem variant="mosaic_vertical" image={image_2} link={link_2} title={title_2} description={description_2} />
    </div>
  </div>
);

const BigImageRightLayout = ({ image_1, image_2, link_1, link_2, title_1, title_2, description_1, description_2 }) => (
  <div className="grid">
    <div className="third">
      <MosaicItem variant="mosaic_vertical" image={image_2} link={link_2} title={title_2} description={description_2} />
    </div>
    <div className="eight">
      <MosaicItem variant="square" image={image_1} link={link_1} title={title_1} description={description_1} />
    </div>
  </div>
);

const MosaicVerticalWidget = ({
  generalDescription,
  image_1,
  image_2,
  link_1,
  link_2,
  title_1,
  title_2,
  description_1,
  description_2,
  layout,
  containerSettings,
}) => (
    <Container settings={containerSettings}>
      <div className="container">
        <div className={`mosaic ${title_1 !== '' ? 'dimmed' : ''}`}>
          {layout === 'bigImageLeft' ?
            <BigImageLeftLayout
              image_1={image_1}
              image_2={image_2}
              link_1={link_1}
              link_2={link_2}
              title_1={title_1}
              title_2={title_2}
              description_1={description_1}
              description_2={description_2}
            /> :
            <BigImageRightLayout
              image_1={image_1}
              image_2={image_2}
              link_1={link_1}
              link_2={link_2}
              title_1={title_1}
              title_2={title_2}
              description_1={description_1}
              description_2={description_2}
            />}

          {generalDescription && <p className="mosaic-description">{generalDescription}</p>}
        </div>
      </div>
    </Container>
  );

MosaicVerticalWidget.title = 'Mosaic (vertical)';
MosaicVerticalWidget.category = 'Mosaic';

MosaicVerticalWidget.defaultProps = {
  generalDescription: '',
  link_1: '',
  link_2: '',
  title_1: '',
  title_2: '',
  description_1: '',
  description_2: '',
};

MosaicVerticalWidget.propTypes = {
  generalDescription: PropTypes.string,
  image_1: PropTypes.any.isRequired,
  image_2: PropTypes.any.isRequired,
  link_1: PropTypes.string,
  link_2: PropTypes.string,
  title_1: PropTypes.string,
  title_2: PropTypes.string,
  description_1: PropTypes.string,
  description_2: PropTypes.string,
  layout: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

MosaicVerticalWidget.defaults = {
  generalDescription: '',
  image_1: 'http://placehold.it/800x800',
  image_2: 'http://placehold.it/307x633',
  link_1: '',
  link_2: '',
  title_1: '',
  title_2: '',
  description_1: '',
  description_2: '',
  layout: 'bigImageLeft',
  containerSettings: {},
};

MosaicVerticalWidget.adminFields = {
  generalDescription: { type: 'text' },
  layout: { type: 'select', options: [['bigImageLeft', 'Big Image Left'], ['bigImageRight', 'Big Image Right']] },
  image_1: { type: 'custom', component: ImagePicker },
  link_1: { type: 'text' },
  title_1: { type: 'text' },
  description_1: { type: 'text' },
  image_2: { type: 'custom', component: ImagePicker },
  link_2: { type: 'text' },
  title_2: { type: 'text' },
  description_2: { type: 'text' },
};

export default MosaicVerticalWidget;
