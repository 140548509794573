import React, { useState, useRef, useEffect } from "react";
import sizeMe from "react-sizeme";
import StackGrid from "react-stack-grid";
import ProjectModal from "./ProjectModal";
import ModalContent from "./ProjectModal/ModalContent";
import { Img } from "@raketa-cms/raketa-cms";

const mediaSizes = {
  desktop: "33%",
  tablet: "50%",
  phone: "100%",
};

const media = (windowWidth) => {
  if (windowWidth >= 940) return "desktop";
  if (windowWidth <= 600) return "phone";
  if (windowWidth > 600 && windowWidth < 940) return "tablet";
};

const onModalOpen = (galleryUrl) => {
  window.oldUrl = window.location.href;
  window.oldScrollPosition = window.pageYOffset;
  window.history.replaceState(null, null, galleryUrl);
};

const backToProjects = () => {
  window.history.replaceState(null, null, window.oldUrl);
  window.scrollTo(0, window.oldScrollPosition);
};

const ProjectGrid = ({ projects, featuredProject, size }) => {
  const [isOpen, setOpen] = useState(false);
  const [isProjectUiVisible, setProjectUiVisibility] = useState(true);
  const [projectData, setProjectData] = useState(projects[0]);
  const [initialSlide, setInitialSlide] = useState(0);
  const { width } = size;
  const grid = useRef(null);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (grid.current) {
        grid.current.updateLayout();
      }
    });
  });

  return (
    <React.Fragment>
      {featuredProject && featuredProject.images[0] && (
        <div className="mosaic dimmed">
          <a
            key={featuredProject.id}
            href={featuredProject.url}
            className="mosaic-item"
            onClick={(e) => {
              e.preventDefault();

              setProjectData(featuredProject);
              setInitialSlide(
                featuredProject.project_images.findIndex(
                  (pi) => pi.id === featuredProject.images[0].id
                )
              );
              onModalOpen(featuredProject.url);
              setOpen(true);
            }}
          >
            <div className="full-width-img">
              <Img
                src={featuredProject.images[0].image}
                variant="lead"
                alt={featuredProject.name}
              />
              <Img
                src={featuredProject.images[0].image}
                variant="square"
                alt={featuredProject.name}
              />
            </div>

            <div className="overlay-content">
              <h3 className="title">{featuredProject.name}</h3>
              {featuredProject.description && (
                <h5 className="sub-title">{featuredProject.description}</h5>
              )}
            </div>
          </a>
        </div>
      )}

      <div className="project-grid">
        <StackGrid
          gridRef={(r) => (grid.current = r)}
          monitorImagesLoaded
          columnWidth={mediaSizes[media(width)]}
          gutterWidth={20}
          gutterHeight={20}
        >
          {projects.map((project) =>
            project.images.map((pi) => (
              <a
                key={pi.ord}
                href={pi.url}
                className="project-grid-item"
                onClick={(e) => {
                  e.preventDefault();

                  setProjectData(project);
                  setInitialSlide(
                    project.project_images.findIndex((el) => el.id === pi.id)
                  );
                  onModalOpen(pi.url);
                  setOpen(true);
                }}
              >
                <div className="overlay-content">
                  <h3 className="title">{project.name}</h3>
                  {project.description && (
                    <h5 className="sub-title">{project.description}</h5>
                  )}
                </div>

                <Img src={pi.image} variant="image" alt={project.name} />
              </a>
            ))
          )}
        </StackGrid>

        {projectData && isOpen && (
          <div className={isProjectUiVisible ? "" : "hide-meta"}>
            <ProjectModal
              isOpen={isOpen}
              onClose={() => {
                setOpen(false);
                backToProjects();
              }}
            >
              <ModalContent
                projectData={projectData}
                initialSlide={initialSlide}
                setProjectUiVisibility={() =>
                  setProjectUiVisibility(!isProjectUiVisible)
                }
              />
            </ProjectModal>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default sizeMe()(ProjectGrid);
