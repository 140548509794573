import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, List, Container } from '@raketa-cms/raketa-cms';

const StatsWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="grid">
        <div className="shift-2">
          <div className="stats">
            {list.map((item, idx) =>
              <p key={idx}><strong>{item.number}</strong> {item.description}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  </Container>
);

StatsWidget.title = 'Statistics';
StatsWidget.category = 'Collections';

StatsWidget.defaultProps = {
  list: [],
};

StatsWidget.propTypes = {
  list: PropTypes.array,
  containerSettings: PropTypes.object.isRequired,
};

StatsWidget.defaults = {
  list: [
    { id: 1, number: '71', description: 'total work hours', },
    { id: 2, number: '35', description: 'hours optimizing geometry & lighting', },
    { id: 3, number: '1', description: 'mood board created', },
    { id: 4, number: '32', description: 'emails exchanged', },
    { id: 5, number: '341', description: '3D people in rendering', },
  ],
  containerSettings: {},
};

const NavigationItem = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Number"
      onChange={value => onChangeItem('number', value)}
      value={settings.number}
    />

    <TextInput
      label="Description"
      onChange={value => onChangeItem('description', value)}
      value={settings.description}
    />
  </div>
);

NavigationItem.defaultProps = {
  settings: {},
};

NavigationItem.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

StatsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) =>
        <NavigationItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default StatsWidget;
