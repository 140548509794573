import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';
import MosaicItem from './MosaicItem';

const MosaicHalfWidget = ({ generalDescription, image_1, image_2, link_1, link_2, title_1, title_2, description_1, description_2, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className={`mosaic ${title_1 !== '' ? 'dimmed' : ''}`}>
        <div className="grid">
          <div className="half">
            <MosaicItem variant="square" image={image_1} link={link_1} title={title_1} description={description_1} />
          </div>
          <div className="half">
            <MosaicItem variant="square" image={image_2} link={link_2} title={title_2} description={description_2} />
          </div>
        </div>
        {generalDescription && <p className="mosaic-description">{generalDescription}</p>}
      </div>
    </div>
  </Container>
);

MosaicHalfWidget.title = 'Mosaic (2)';
MosaicHalfWidget.category = 'Mosaic';

MosaicHalfWidget.defaultProps = {
  generalDescription: '',
  link_1: '',
  link_2: '',
  title_1: '',
  title_2: '',
  description_1: '',
  description_2: '',
};

MosaicHalfWidget.propTypes = {
  generalDescription: PropTypes.string.isRequired,
  image_1: PropTypes.any.isRequired,
  image_2: PropTypes.any.isRequired,
  link_1: PropTypes.string,
  link_2: PropTypes.string,
  title_1: PropTypes.string,
  title_2: PropTypes.string,
  description_1: PropTypes.string,
  description_2: PropTypes.string,
  containerSettings: PropTypes.object.isRequired,
};

MosaicHalfWidget.defaults = {
  generalDescription: '',
  image_1: 'http://placehold.it/470x470',
  image_2: 'http://placehold.it/470x470',
  link_1: '',
  link_2: '',
  title_1: 'Title',
  title_2: 'Title',
  description_1: '',
  description_2: '',
  containerSettings: {},
};

MosaicHalfWidget.adminFields = {
  generalDescription: { type: 'text' },
  image_1: { type: 'custom', component: ImagePicker },
  link_1: { type: 'text' },
  title_1: { type: 'text' },
  description_1: { type: 'text' },
  image_2: { type: 'custom', component: ImagePicker },
  link_2: { type: 'text' },
  title_2: { type: 'text' },
  description_2: { type: 'text' },
};

export default MosaicHalfWidget;
