import React from "react";
import { Img } from "@raketa-cms/raketa-cms";

const VrSlide = ({ vr }) => (
  <div className="tour-wrapper">
    <Img src={vr.image} variant="lead" />
  </div>
);

export default VrSlide;
