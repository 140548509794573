import React from "react";
import PropTypes from "prop-types";

import { TextInput, List, Container } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import VideoPicker from "../pickers/VideoPicker";

import VideoThumb from "./TeamWidget/VideoThumb";

const ThumbsWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="team-widget">
        <div className="grid">
          {list.map((item) => (
            <VideoThumb key={item.id} item={item} />
          ))}
        </div>
      </div>
    </div>
  </Container>
);

ThumbsWidget.title = "Team";
ThumbsWidget.category = "Collections";
ThumbsWidget.dialogSize = "large";

ThumbsWidget.defaultProps = {
  list: [],
};

ThumbsWidget.propTypes = {
  list: PropTypes.array,
  containerSettings: PropTypes.object.isRequired,
};

ThumbsWidget.defaults = {
  list: [
    {
      id: 1,
      image: "http://placehold.it/225x280",
      imageAlt: "http://placehold.it/225x280",
      name: "John Doe",
      position: "CFO",
      linkedin: "",
    },
    {
      id: 2,
      image: "http://placehold.it/225x280",
      imageAlt: "http://placehold.it/225x280",
      name: "John Doe",
      position: "CFO",
      linkedin: "",
    },
    {
      id: 3,
      image: "http://placehold.it/225x280",
      imageAlt: "http://placehold.it/225x280",
      name: "John Doe",
      position: "CFO",
      linkedin: "",
    },
  ],
  containerSettings: {},
};

const ThumbField = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />
    <ImagePicker
      label="Image (hover)"
      onChange={(value) => onChangeItem("imageAlt", value)}
      value={settings.imageAlt}
    />
    <VideoPicker
      label="Video (hover)"
      onChange={(value) => onChangeItem("hoverVideo", value)}
      value={settings.hoverVideo}
    />
    <TextInput
      label="Name"
      onChange={(value) => onChangeItem("name", value)}
      value={settings.name}
    />
    <TextInput
      label="Position"
      onChange={(value) => onChangeItem("position", value)}
      value={settings.position}
    />
    <TextInput
      label="LinkedIn (optional)"
      onChange={(value) => onChangeItem("linkedin", value)}
      value={settings.linkedin}
    />
  </div>
);

ThumbField.defaultProps = {
  settings: {},
};

ThumbField.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

ThumbsWidget.adminFields = (items, onChange, settings) => (
  <List
    listItem={(settings, onChangeItem) => (
      <ThumbField settings={settings} onChangeItem={onChangeItem} />
    )}
    onChangeList={onChange}
    items={items}
  />
);

export default ThumbsWidget;
