import React from 'react';
import SectionTitleWidget from './SectionTitleWidget';
import ImageWidget from './ImageWidget';
import TextBoxWidget from './TextBoxWidget';
import VideoWidget from './VideoWidget';
import LeadImageWidget from './LeadImageWidget';
import NavigationWidget from './NavigationWidget';
import SloganWidget from './SloganWidget';
import TestimonialWidget from './TestimonialWidget';
import PageTitleWidget from './PageTitleWidget';
import ArticleTitleWidget from './ArticleTitleWidget';
import StatsWidget from './StatsWidget';
import ThumbsWidget from './ThumbsWidget';
import MosaicFullWidget from './MosaicFullWidget';
import MosaicHalfWidget from './MosaicHalfWidget';
import MosaicThirdWidget from './MosaicThirdWidget';
import MosaicOneTwoWidget from './MosaicOneTwoWidget';
import MosaicVerticalWidget from './MosaicVerticalWidget';
import HtmlWidget from './HtmlWidget';
import VrWidget from './VrWidget';
import TourWidget from './TourWidget';

export default {
  SectionTitleWidget,
  ImageWidget,
  TextBoxWidget,
  VideoWidget,
  LeadImageWidget,
  NavigationWidget,
  SloganWidget,
  TestimonialWidget,
  PageTitleWidget,
  ArticleTitleWidget,
  StatsWidget,
  ThumbsWidget,
  MosaicFullWidget,
  MosaicHalfWidget,
  MosaicThirdWidget,
  MosaicOneTwoWidget,
  MosaicVerticalWidget,
  HtmlWidget,
  VrWidget,
  TourWidget,
};
