import React, { useEffect } from 'react';
import { imageSrc } from '@raketa-cms/raketa-cms';

const VideoSlide = ({ video, isPlaying }) => {
  let videoRef = React.createRef();

  useEffect(() => {
    (isPlaying && video) ? videoRef.current.play() : videoRef.current.pause();
  });

  return (
    <div className="video-wrapper">
      <video ref={videoRef} poster={imageSrc(video.image, 'lead')} className="video-player" loop playsInline muted>
        <source src={video.background.webm_url} type="video/webm" />
        <source src={video.background.mp4_url} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
    </div>
  );
}

export default VideoSlide;