import React from "react";
import styled from "styled-components";

import { TextInput, Img, Dialog } from "@raketa-cms/raketa-cms";

const ImageControl = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const ImageWrapper = styled.div`
  margin-right: 16px;

  & > img {
    object-fit: contain;
    display: block;
    width: 100px;
    height: 100px;
  }
`;

const DialogWrapper = styled.div`
  z-index: 9999;
`;

const VideoList = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
`;

const VideoItemWrapper = styled.button`
  border: 0;
  -webkit-appearance: none;
  outline: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  display: block;
  margin-right: 8px;
  margin-bottom: 8px;
  ${(props) =>
    props.selected ? "border: 4px solid green;" : "border: 4px solid #ddd;"}

  & > img {
    object-fit: contain;
    width: 100px;
    height: 100px;
  }
`;

const VideoItem = ({ selected, video, onSelect, onFastSelect }) => (
  <VideoItemWrapper
    selected={selected}
    onClick={() => onSelect(video)}
    onDoubleClick={() => onFastSelect(video)}
  >
    <Img src={video.image} variant="thumb" />
  </VideoItemWrapper>
);

class VideoPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pickerOpen: false,
      selectedVideo: this.props.value || false,
      value: this.props.value || false,
      q: "",
      videos: [],
    };
  }

  fetchData(q, callback) {
    const params = q !== "" && q.length > 2 ? { q } : {};

    $.get("/videos", params, (videos) => {
      this.setState({ videos }, () => {
        callback();
      });
    });
  }

  handleSearch(q) {
    this.setState({ q }, () => {
      this.fetchData(q, () => {});
    });
  }

  handleOpenPicker() {
    const { q } = this.state;

    this.fetchData(q, () => {
      this.setState({ pickerOpen: true });
    });
  }

  handleSelectVideo(selectedVideo) {
    this.setState({ selectedVideo });
  }

  handlePickVideo() {
    const { selectedVideo } = this.state;
    this.setState({ value: selectedVideo, pickerOpen: false }, () => {
      if (this.props.onChange) this.props.onChange(selectedVideo);
    });
  }

  handleClear() {
    this.setState({ value: null }, () => {
      if (this.props.onChange) this.props.onChange(null);
    });
  }

  handleFastSelectVideo(selectedVideo) {
    this.setState({ selectedVideo }, () => this.handlePickVideo());
  }

  render() {
    const { label } = this.props;
    const { pickerOpen, selectedVideo, value, q, videos } = this.state;

    return (
      <div>
        <ImageControl>
          <ImageWrapper>
            <Img
              src={value && value.hasOwnProperty("image") ? value.image : false}
              variant="thumb"
            />
          </ImageWrapper>

          <div>
            <label className="raketa-label">{label}</label>
            <button
              type="button"
              className="raketa-button"
              onClick={() => this.handleOpenPicker()}
            >
              Select Video...
            </button>
            {value && (
              <button
                type="button"
                className="raketa-button"
                onClick={() => this.handleClear()}
              >
                Clear
              </button>
            )}
          </div>
        </ImageControl>

        <DialogWrapper style={{ display: pickerOpen ? "" : "none" }}>
          <Dialog
            open={pickerOpen}
            onClose={() => this.setState({ pickerOpen: false })}
            title="Video Browser"
            primaryLabel="Select"
            dialogSize="large"
            onPrimary={() => this.handlePickVideo()}
          >
            <TextInput
              label="Search videos"
              value={q}
              onChange={(q) => this.handleSearch(q)}
            />

            <VideoList>
              {videos.map((video) => (
                <VideoItem
                  key={video.id}
                  selected={
                    parseInt(selectedVideo.id, 10) === parseInt(video.id, 10)
                  }
                  video={video}
                  onSelect={(video) => this.handleSelectVideo(video)}
                  onFastSelect={(video) => this.handleFastSelectVideo(video)}
                />
              ))}
            </VideoList>
          </Dialog>
        </DialogWrapper>
      </div>
    );
  }
}

export default VideoPicker;
