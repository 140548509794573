import React from 'react';

class Modal extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', (e) => {
      if (this.modal) {
        this.handleEscKey(e);
      }
    }, false);
  }

  handleEscKey(e) {
    if (e.keyCode === 27) {
      this.props.onClose();
    }
  }

  render() {
    const { open, onClose, children, className, modalWrapperClass } = this.props;

    return (
      <div className={`modal ${modalWrapperClass ? modalWrapperClass : ''} ${open === true ? 'modal-show' : ''}`} ref={(modal) => { this.modal = modal; }}>
        <div className={`modal-cnt ${className !== '' ? className : ''}`}>
          <button className="close" type="button" onClick={() => onClose()}>X</button>

          {children}
        </div>

        <div className="modal-overlay" onClick={() => onClose()} />
      </div>
    );
  }
}

Modal.defaultProps = {
  className: '',
};

export default Modal;
