import React from 'react';
import ProjectModal from './ProjectModal';
import ModalContent from './ProjectModal/ModalContent';

class ProjectPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUIVisible: true,
    }
  }

  render() {
    const { project, initialSlide } = this.props;
    const { isUIVisible } = this.state;

    return (
      <div className={isUIVisible ? '' : 'hide-meta'}>
        <ProjectModal
          isOpen={true}
          onClose={() => {
            window.location.href = '/work';
          }}
        >
          <ModalContent
            projectData={project}
            initialSlide={initialSlide}
            setProjectUiVisibility={() => this.setState({ isUIVisible: !isUIVisible })}
          />
        </ProjectModal>
      </div>
    );
  }
}

export default ProjectPage;
