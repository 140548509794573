import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';

const TourWidget = ({ tourUrl, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="tour-widget">
        {tourUrl && <iframe width="100%" className="vr-player1" src={`${tourUrl}?embed=1`} frameBorder="0" allowFullScreen></iframe>}
      </div>
    </div>
  </Container>
);

TourWidget.title = 'VR Tour';
TourWidget.category = 'Media';

TourWidget.propTypes = {
  tourUrl: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

TourWidget.defaults = {
  tourUrl: '',
  containerSettings: {},
};

TourWidget.adminFields = {
  tourUrl: { type: 'text' },
};

export default TourWidget;
