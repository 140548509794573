import React from "react";
import Modal from "../Modal";

const ProjectModal = ({ onClose, isOpen, children }) => (
  <Modal open={isOpen} onClose={onClose} className="project-modal">
    {children}
  </Modal>
);

export default ProjectModal;
