import React from "react";
import { PageBuilder } from "@raketa-cms/raketa-cms";
import {
  MediaManager,
  MediaManagerContext,
} from "@raketa-cms/raketa-image-picker";
import LIBRARY from "./widgets";

const THEMES = [
  ["none", "None"],
  ["light", "Light"],
  ["dark", "Dark"],
];

class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    const { children } = this.props;
    return <React.Fragment>{children}</React.Fragment>;
  }
}

const mediaManager = new MediaManager("/images/client/");

const AdminBuilder = ({
  page: defaultPage,
  page_context,
  host,
  save_url,
  back_url,
}) => {
  const [dirty, setDirty] = React.useState(false);
  const [page, setPage] = React.useState(defaultPage);

  const handleChange = (page) => {
    setPage(page);
    setDirty(true);
  };

  const handleSave = (page) => {
    fetch(save_url, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
        "content-type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ widgets: page.widgets }),
    })
      .then(() => {
        setDirty(false);
      })
      .catch((error) => {
        console.error(error);
        alert("An error occured saving this page. ");
      });
  };

  return (
    <ErrorBoundary>
      <MediaManagerContext.Provider value={mediaManager}>
        <PageBuilder
          host={host}
          dirty={dirty}
          library={LIBRARY}
          themes={THEMES}
          page={page}
          onChange={handleChange}
          onSave={handleSave}
          onExit={() => (window.location.href = back_url)}
        />
      </MediaManagerContext.Provider>
    </ErrorBoundary>
  );
};

AdminBuilder.defaultProps = {
  host: "http://localhost:3000/",
};

export default AdminBuilder;
