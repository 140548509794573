import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';
import MosaicItem from './MosaicItem';

const MosaicThirdWiget = ({
  generalDescription,
  image_1,
  image_2,
  image_3,
  link_1,
  link_2,
  link_3,
  title_1,
  title_2,
  title_3,
  description_1,
  description_2,
  description_3,
  containerSettings
}) => (
    <Container settings={containerSettings}>
      <div className="container">
        <div className={`mosaic ${title_1 !== '' ? 'dimmed' : ''}`}>
          <div className="grid">
            <div className="third">
              <MosaicItem variant="square" image={image_1} link={link_1} title={title_1} description={description_1} />
            </div>
            <div className="third">
              <MosaicItem variant="square" image={image_2} link={link_2} title={title_2} description={description_2} />
            </div>
            <div className="third">
              <MosaicItem variant="square" image={image_3} link={link_3} title={title_3} description={description_3} />
            </div>
          </div>
          {generalDescription && <p className="mosaic-description">{generalDescription}</p>}
        </div>
      </div>
    </Container>
  );

MosaicThirdWiget.title = 'Mosaic (3)';
MosaicThirdWiget.category = 'Mosaic';

MosaicThirdWiget.defaultProps = {
  generalDescription: '',
  link_1: '',
  link_2: '',
  link_3: '',
  title_1: '',
  title_2: '',
  title_3: '',
  description_1: '',
  description_2: '',
  description_3: '',
};

MosaicThirdWiget.propTypes = {
  generalDescription: PropTypes.string,
  image_1: PropTypes.any.isRequired,
  image_2: PropTypes.any.isRequired,
  image_3: PropTypes.any.isRequired,
  link_1: PropTypes.string,
  link_2: PropTypes.string,
  link_3: PropTypes.string,
  title_1: PropTypes.string,
  title_2: PropTypes.string,
  title_3: PropTypes.string,
  description_1: PropTypes.string,
  description_2: PropTypes.string,
  description_3: PropTypes.string,
  containerSettings: PropTypes.object.isRequired,
};

MosaicThirdWiget.defaults = {
  generalDescription: '',
  image_1: 'http://placehold.it/470x470',
  image_2: 'http://placehold.it/470x470',
  image_3: 'http://placehold.it/470x470',
  link_1: '',
  link_2: '',
  link_3: '',
  title_1: '',
  title_2: '',
  title_3: '',
  description_1: '',
  description_2: '',
  description_3: '',
  containerSettings: {},
};

MosaicThirdWiget.adminFields = {
  generalDescription: { type: 'text' },
  image_1: { type: 'custom', component: ImagePicker },
  link_1: { type: 'text' },
  title_1: { type: 'text' },
  description_1: { type: 'text' },
  image_2: { type: 'custom', component: ImagePicker },
  link_2: { type: 'text' },
  title_2: { type: 'text' },
  description_2: { type: 'text' },
  image_3: { type: 'custom', component: ImagePicker },
  link_3: { type: 'text' },
  title_3: { type: 'text' },
  description_3: { type: 'text' },
};

export default MosaicThirdWiget;
