import React from 'react';

const ProjectInfo = ({ title, description }) => (
  <div className="project-info">
    <h3 className="project-info-title">{title}</h3>
    <p className="project-info-description">{description}</p>
  </div>
);

export default ProjectInfo;
