import React from 'react';
import { ListInput, LinkSettings } from '@raketa-cms/raketa-cms';

class MenuEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.value.length > 0 ? props.value : [{ id: 1, link: LinkSettings.defaults }],
    };
  }

  render() {
    const { name } = this.props;
    const { items } = this.state;

    return (
      <div className="form-group">
        <label className="control-label">Menu items</label>
        <ListInput
          template={{ link: LinkSettings.defaults }}
          listItem={(item, onChange, idx) => {
            return (
              <LinkSettings
                key={idx}
                label="Link"
                onChange={newValue => onChange('link', newValue)}
                value={item.link}
              />
            );
          }}
          onChangeList={(_, items) => this.setState({ items })}
          items={items}
        />

        <input type="hidden" name={name} value={JSON.stringify(items)} />
      </div>
    );
  }
}

export default MenuEditor;
