import React from 'react';
import PropTypes from 'prop-types';
import { Img, Container } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';

const VrWiget = ({ image, containerSettings, link }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="vr-widget mosaic">
        <div className="grid">
          <div className="full-width">
            {link !== '' ? <a href={link} target="_blank"><Img src={image} variant="mosaic_full" /> <span className="vr-icon"></span></a> : <Img src={image} variant="mosaic_full" />}
          </div>
        </div>
      </div>
    </div>
  </Container>
);

VrWiget.title = 'VR';
VrWiget.category = 'Media';

VrWiget.defaultProps = {
  link: '',
}

VrWiget.propTypes = {
  image: PropTypes.any.isRequired,
  link: PropTypes.string,
  containerSettings: PropTypes.object.isRequired,
};

VrWiget.defaults = {
  image: 'http://placehold.it/960x450',
  containerSettings: {},
  link: '',
};

VrWiget.adminFields = {
  image: { type: 'custom', component: ImagePicker },
  link: { type: 'text' },
};

export default VrWiget;
