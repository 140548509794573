import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';
import MosaicItem from './MosaicItem';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';

const MosaicFullWidget = ({ variant, image, link, title, description, generalDescription, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className={`mosaic ${title !== '' ? 'dimmed' : ''}`}>
        <div className="grid">
          <div className="full-width">
            <MosaicItem variant="mosaic_full" image={image} link={link} title={title} description={description} />
            {generalDescription && <p className="mosaic-description">{generalDescription}</p>}
          </div>
        </div>
      </div>
    </div>
  </Container>
);

MosaicFullWidget.title = 'Mosaic (1)';
MosaicFullWidget.category = 'Mosaic';

MosaicFullWidget.defaultProps = {
  link: '',
  title: 'Title',
  description: '',
  generalDescription: '',
}

MosaicFullWidget.propTypes = {
  image: PropTypes.any.isRequired,
  link: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  generalDescription: PropTypes.string,
  containerSettings: PropTypes.object.isRequired,
};

MosaicFullWidget.defaults = {
  containerSettings: {},
  image: 'http://placehold.it/960x450',
  link: '',
  title: 'Title',
  description: '',
  generalDescription: '',
};

MosaicFullWidget.adminFields = {
  image: { type: 'custom', component: ImagePicker },
  link: { type: 'text' },
  title: { type: 'text' },
  description: { type: 'text' },
  generalDescription: { type: 'text' },
};

export default MosaicFullWidget;
