import React from "react";
import { Img } from "@raketa-cms/raketa-cms";

const TeamMemberDescription = ({ item }) => (
  <div className="team-member-description">
    <div>
      {item.name && <h3 className="member-name">{item.name}</h3>}
      {item.position && <h4 className="member-position">{item.position}</h4>}
    </div>
    <div className="social-icon">
      {item.linkedin && (
        <a href={item.linkedin} target="_blank">
          LinkedIn
        </a>
      )}
    </div>
  </div>
);

class VideoPanel extends React.Component {
  play() {
    // if (window.innerWidth > 800) {
    this.video.currentTime = 0;
    this.video.play();
    // }
  }

  pause() {
    // if (window.innerWidth > 800) {
    this.video.pause();
    // }
  }

  render() {
    const { mp4, webm } = this.props;

    return (
      <video
        preload="auto"
        playsInline
        autoPlay
        muted
        loop
        ref={(video) => (this.video = video)}
        onMouseOver={() => this.play()}
        onMouseOut={() => this.pause()}
      >
        {mp4 && <source src={mp4} type="video/mp4" />}
        {webm && <source src={webm} type="video/webm" />}
        Your browser does not support HTML5 video.
      </video>
    );
  }
}

class VideoThumb extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //
    };
  }

  render() {
    const { item } = this.props;

    return (
      <div className="third team-member">
        <Img src={item.image} variant="thumbs" className="first" />
        {item.hoverVideo && item.hoverVideo.mp4_url ? (
          <div className="team-member-video last">
            <VideoPanel
              mp4={item.hoverVideo.mp4_url}
              webm={item.hoverVideo.webm_url}
            />
          </div>
        ) : (
          <Img src={item.imageAlt} variant="thumbs" className="last" />
        )}
        {item.name && <TeamMemberDescription item={item} />}
      </div>
    );
  }
}

export default VideoThumb;
