import React from "react";
import Swiper from "react-id-swiper";
import { Img } from "@raketa-cms/raketa-cms";
import ProjectInfo from "./ProjectInfo";
import VideoSlide from "./VideoSlide";
import VrSlide from "./VrSlide";

const ProjectSlider = ({
  initialSlide,
  currentSlide,
  projectData,
  isPlaying,
  onChangeSlide,
}) => {
  const [swiper, updateSwiper] = React.useState(null);
  const [swiperOn, setSwiperOn] = React.useState(false);

  if (swiper && !swiperOn) {
    swiper.on("slideChange", function () {
      onChangeSlide(swiper.realIndex);
    });

    setSwiperOn(true);
  }

  return (
    <Swiper
      keyboard
      shouldSwiperUpdate
      initialSlide={initialSlide}
      spaceBetween={0}
      loop={projectData.similar_projects.length === 0}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      containerClass="ui-visible swiper-container"
      getSwiper={updateSwiper}
    >
      {projectData.project_images.map((item, slideIdx) => (
        <div key={item.id}>
          {item.slide_type === "image" && (
            <Img src={item.image} variant="lead" />
          )}
          {item.slide_type === "video" && (
            <VideoSlide
              video={item}
              isPlaying={isPlaying && slideIdx === currentSlide}
            />
          )}
          {item.slide_type === "vr" && <VrSlide vr={item} />}
        </div>
      ))}

      {projectData.similar_projects.length > 0 && (
        <div className="last-slide-inner">
          <div className="suggested-prjects-wrapper">
            <h1 className="last-slide-title">Similar Projects</h1>
            <div className="suggested-projects">
              {projectData.similar_projects.map((project) => (
                <a href={project.url} className="project" key={project.id}>
                  <Img src={project.image} variant="thumb" />

                  <div className="overlay-content">
                    <h2 className="title">{project.name}</h2>
                  </div>
                </a>
              ))}
            </div>

            <div className="btn-wrapper">
              <a href="/contact" className="btn-contact">
                Contact us
              </a>
            </div>
          </div>
        </div>
      )}
    </Swiper>
  );
};

class ModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: props.initialSlide,
      isPlaying: false,
    };
  }

  handleSlideChange = (currentSlide) => {
    const { projectData } = this.props;

    this.setState({ currentSlide }, () => {
      const projectImage = projectData.project_images[currentSlide];

      if (projectImage)
        window.history.replaceState(null, null, projectImage.url);
    });
  };

  render() {
    const { projectData, setProjectUiVisibility, initialSlide } = this.props;
    const { currentSlide, isPlaying } = this.state;

    const projectImage = projectData.project_images[currentSlide];
    const isVideo = projectImage && projectImage.slide_type === "video";
    const isVr = projectImage && projectImage.slide_type === "vr";
    const hasSimilarProjects = projectData.similar_projects.length > 0;
    const isLastSlide = currentSlide === projectData.project_images.length;

    return (
      <div className="project-image-new">
        {(!hasSimilarProjects || (hasSimilarProjects && !isLastSlide)) && (
          <div className="meta-trigger" onClick={setProjectUiVisibility} />
        )}

        <ProjectSlider
          projectData={projectData}
          initialSlide={initialSlide}
          currentSlide={currentSlide}
          isPlaying={isPlaying}
          onChangeSlide={this.handleSlideChange}
        />

        {isVideo && (
          <button
            onClick={() => this.setState({ isPlaying: !isPlaying })}
            type="button"
            className={`play-btn ${isPlaying ? "pause" : ""}`}
          >
            Play/Pause
          </button>
        )}

        {isVr && (
          <React.Fragment>
            <a
              href={`/tours/${projectImage.tour.slug}`}
              target="_blank"
              className="btn-launch-tour"
            />
          </React.Fragment>
        )}

        <ProjectInfo
          title={projectImage ? projectImage.slide_title : projectData.name}
          description={
            projectImage
              ? projectImage.slide_description
              : projectData.description
          }
        />
      </div>
    );
  }
}

ModalContent.defaultProps = {
  initialSlide: 0,
};

export default ModalContent;
